import React from "react";

import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";

import type { ReactNode } from "react";
import type { BoxProps } from "~components/Box";
import type { MotionAttrEnum } from "~utils/motion_one/motion_attribute_selectors";

export interface AnimatedElementProps extends Omit<BoxProps, "as"> {
  children: ReactNode;
  animationLevel: Extract<MotionAttrEnum, "primary" | "secondary">;
}

export function AnimatedElement({
  children,
  animationLevel,
  ...rest
}: AnimatedElementProps) {
  return (
    <Box
      /** @todo [MW-1309]: Don't hard-code opacity into `AnimatedElement` (makes it hard to do isolated testing on the component) */
      opacity="0"
      as="figure"
      data-motion={ANIMATED[animationLevel]}
      {...rest}
    >
      {children}
    </Box>
  );
}
