import React from "react";

import clsx from "clsx";

import { Box } from "~components/Box";
import { StoryblokEditable } from "~components/StoryblokEditable";

import * as styles from "./styles.css";

import type { AnimLayerFakeButtonProps } from "~animations/__layers__/mock_ui_components/AnimLayerFakeButton";
import type { AnimLayerIconListProps } from "~animations/__layers__/mock_ui_components/AnimLayerIconList";
import type { AnimLayerInfoDialogProps } from "~animations/__layers__/mock_ui_components/AnimLayerInfoDialog";
import type { AnimLayerUiCenteredTextProps } from "~animations/__layers__/mock_ui_components/AnimLayerUiCenteredText";
import type { AnimLayerUiPayoutMethodProps } from "~animations/__layers__/mock_ui_components/AnimLayerUiPayoutMethod";
import type { PaymentSuccessDetail } from "~animations/mock_ui/AnimMockUiLoading";
import type { BoxProps } from "~components/Box";
import type { ComponentMap } from "~types/global.types";
import type {
  StoryblokBlok,
  StoryblokFieldMedia,
} from "~types/storyblok.types";

export interface Animation extends StoryblokBlok {
  title?: string;
  animLayerInfoDialog?: AnimLayerInfoDialogProps[];
  animLayerPaymentMethods?: Array<StoryblokBlok & AnimLayerIconListProps>;
  image?: StoryblokFieldMedia;
  details?: PaymentSuccessDetail[];
  animLayerFakeButton?: (StoryblokBlok & AnimLayerFakeButtonProps)[];
  animLayerUiCenteredText?: Array<StoryblokBlok & AnimLayerUiCenteredTextProps>;
  amount?: number | string;
  animLayerPayoutMethod?: Array<StoryblokBlok & AnimLayerUiPayoutMethodProps>;
}

export interface BlokWithAnimation extends StoryblokBlok {
  animation: Array<Animation>;
}

interface AnimationSliderProps extends BoxProps {
  items: Array<BlokWithAnimation>;
  current?: number;
  previous?: number;
  animationComponentMap: ComponentMap;
}

export function AnimationSlider({
  items,
  className: userClassName,
  animationComponentMap,
  ...rest
}: AnimationSliderProps) {
  return (
    <Box
      position="relative"
      width="100%"
      height="100%"
      className={clsx(userClassName, styles.animationsWrapper)}
      {...rest}
    >
      {items.map((item, index) => {
        /** Get the animation component configured in the Storyblok */
        const { animation: animationBlokArray } = item;
        const [animationBlokProps] = animationBlokArray || [];

        /** Remove keys that may be populated in the CMS config, but are not required for this use case */
        // delete animationBlokProps?.animLayerAlert;
        // delete animationBlokProps?.animLayerNotification;

        /** Get the corresponding react component */
        const AnimationComponent =
          (animationBlokProps?.component &&
            animationComponentMap[animationBlokProps.component]) ||
          null;

        if (!AnimationComponent) return null;

        return (
          <StoryblokEditable key={item._uid} {...animationBlokProps}>
            <Box
              key={item._uid}
              /** Tag this as group containing multiple elements to be animated */
              data-motion-group={index}
              /**
               * Ensure illustrations can be stacked on top of each other,
               * and that the container fills the entire parent container
               */
              position="absolute"
              inset="0"
              /** Ensure illustrations are placed in center of container */
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <AnimationComponent {...animationBlokProps} />
            </Box>
          </StoryblokEditable>
        );
      })}
    </Box>
  );
}
